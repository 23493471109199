import React, { Fragment, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { AppContext } from "../../../components/AppProvider";

import { _CFG } from "../../../modules/config";
import { CloseButton, H2, Wrapper, Content } from "./Components";
import { _API, _U, _CK } from "../../../modules/utils";
import { isMobile } from "react-device-detect";

import { default as AgendaView } from "./Agenda";
import { default as LocationView } from "./Location";
import { default as FeedbackView } from "./Feedback";
import { default as SessionView } from "./Session";
import { default as BoothView, Booth as BoothDetailView } from "./Booth";

import Registration, { RegView, RegCheckView, RegDetailView } from "./Registration";

export const ContentModal = (props) => {
    const { isMenuView, setIsMenuView, menuLabel } = props;

    const getView = () => {
        switch (isMenuView) {
            case "location":
                return <LocationView />;
            case "booth":
                return <BoothView />;
            case "survey":
            case "feedback":
                return <FeedbackView />;
            default:
                return <ContentView code={isMenuView} />;
        }
    };

    return (
        <Modal isOpen={true} style={isMobile ? basicModalStyles.mobile : basicModalStyles.pc}>
            <H2>{menuLabel}</H2>
            <CloseButton
                className="btn btn-default"
                onClick={() => {
                    setIsMenuView(null);
                }}
            >
                <i className="fa fa-remove"></i>
            </CloseButton>
            {getView()}
        </Modal>
    );
};

export const ContentImageModal = (props) => {
    const { cfg, auth } = useContext(AppContext);
    const { isMenuView, setIsMenuView, menuLabel } = props;
    const [cvFiles, setCvFiles] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        loadData(isMenuView);
    }, []);

    const loadData = (code) => {
        const { idx: siteIdx } = cfg;
        const { idx: siteEventIdx } = cfg.event;

        _API.get({
            path: "/v1/site/event/content",
            data: {
                siteIdx,
                siteEventIdx,
                code,
                fileType: "ONLY-IMAGE",
            },
        }).then((res) => {
            const { files } = res.data;
            setCvFiles(files);
            setIsLoaded(true);
        });
    };

    if (!isLoaded) {
        return "";
    }

    return (
        <>
            {cvFiles?.length > 0 ? (
                <Lightbox
                    reactModalStyle={{ overlay: { zIndex: 10000 } }}
                    imageTitle={menuLabel}
                    mainSrc={cvFiles[imageIndex]?.url}
                    nextSrc={cvFiles[[imageIndex + 1] % cvFiles.length]?.url}
                    prevSrc={cvFiles[(imageIndex + cvFiles.length - 1) % cvFiles.length]?.url}
                    onCloseRequest={() => {
                        setImageIndex(0);
                        setIsMenuView(null);
                    }}
                    onMovePrevRequest={() => setImageIndex((imageIndex + cvFiles.length - 1) % cvFiles.length)}
                    onMoveNextRequest={() => setImageIndex((imageIndex + 1) % cvFiles.length)}
                    animationDisabled={false}
                    animationDuration={1000}
                />
            ) : (
                <Modal isOpen={true} style={isMobile ? basicModalStyles.mobile : basicModalStyles.pc}>
                    <H2>{menuLabel}</H2>
                    <CloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setIsMenuView(null);
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </CloseButton>
                    <ContentView code="cv" />
                </Modal>
            )}
        </>
    );
};

export const RegistrationModal = (props) => {
    const { isMenuView, setIsMenuView } = props;

    return (
        <Modal isOpen={isMenuView === "registration"} style={isMobile ? basicModalStyles.mobile : basicModalStyles.pc}>
            <CloseButton
                className="btn btn-default"
                onClick={() => {
                    setIsMenuView(null);
                }}
            >
                <i className="fa fa-remove"></i>
            </CloseButton>
            <RegView
                onComplete={() => {
                    setIsMenuView("registrationCheck");
                }}
                onCancel={() => {
                    setIsMenuView(null);
                }}
            />
        </Modal>
    );
};

export const RegistrationCheckModal = (props) => {
    const { isMenuView, setIsMenuView } = props;

    return (
        <Modal isOpen={isMenuView === "registrationCheck"} style={isMobile ? smallModalStyles.mobile : smallModalStyles.pc}>
            <CloseButton
                className="btn btn-default"
                onClick={() => {
                    setIsMenuView(null);
                }}
            >
                <i className="fa fa-remove"></i>
            </CloseButton>
            <RegCheckView
                onComplete={() => {
                    setIsMenuView("registrationView");
                }}
                onCancel={() => {
                    setIsMenuView(null);
                }}
            />
        </Modal>
    );
};

export const RegistrationViewModal = (props) => {
    const { isMenuView, setIsMenuView } = props;

    return (
        <Modal isOpen={isMenuView === "registrationView"} style={isMobile ? mediumModalStyles.mobile : mediumModalStyles.pc}>
            <CloseButton
                className="btn btn-default"
                onClick={() => {
                    setIsMenuView(null);
                }}
            >
                <i className="fa fa-remove"></i>
            </CloseButton>
            <RegDetailView
                onComplete={() => {
                    setIsMenuView("registration");
                }}
                onCancel={() => {
                    setIsMenuView(null);
                }}
            />
        </Modal>
    );
};

const ContentView = (props) => {
    let code = props.match?.params?.code;
    if (!code) {
        code = props.code;
    }

    return (
        <Wrapper>
            <Content code={code} />
        </Wrapper>
    );
};

const basicModalStyles = {
    mobile: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "4rem",
            left: "1rem",
            right: "1rem",
            bottom: "4rem",
            borderRadius: "10px",
            paddingBottom: "3rem",
            border: "0",
        },
    },
    pc: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "4rem",
            left: "25%",
            right: "25%",
            bottom: "4rem",
            borderRadius: "10px",
            paddingBottom: "3rem",
            border: "0",
        },
    },
};

const bigModalStyles = {
    mobile: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "8rem",
            left: "1rem",
            right: "1rem",
            bottom: "8rem",
            borderRadius: "10px",
            paddingBottom: "3rem",
            border: "0",
        },
    },
    pc: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "10%",
            left: "5%",
            right: "5%",
            bottom: "10%",
            borderRadius: "10px",
            paddingBottom: "3rem",
            border: "0",
        },
    },
};

const mediumModalStyles = {
    mobile: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "5rem",
            left: "1rem",
            right: "1rem",
            bottom: "5rem",
            borderRadius: "10px",
            paddingBottom: "3rem",
            border: "0",
        },
    },
    pc: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "16%",
            left: "25%",
            right: "25%",
            bottom: "16%",
            borderRadius: "10px",
            paddingBottom: "3rem",
            border: "0",
        },
    },
};

const smallModalStyles = {
    mobile: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "8rem",
            left: "1rem",
            right: "1rem",
            bottom: "8rem",
            borderRadius: "10px",
            paddingBottom: "3rem",
            border: "0",
        },
    },
    pc: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "26%",
            left: "25%",
            right: "25%",
            bottom: "26%",
            borderRadius: "10px",
            paddingBottom: "3rem",
            border: "0",
        },
    },
};
