import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import WebSocket from "react-websocket";
import swal from "sweetalert";
import { AppContext } from "../AppProvider";

import { _CFG } from "../../modules/config";
import { _U, _CK } from "../../modules/utils";

const _OPTIONS = [
    { action: "BROADCAST", label: "전체" },
    { action: "IAM", label: "내 접속 ID" },
    { action: "ECHO", label: "에코" },
];

export default (props) => {
    const { debug, onConnected, onDisconnected, onMessage } = props;
    const { cfg, iam, saveAuth } = useContext(AppContext);

    const socket = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const [debugMessages, setDebugMessages] = useState([]);

    const onConnect = () => {
        setIsConnected(true);
        addDebugMessage(":: Socket Connect!");
        onConnected && onConnected();

        try {
            const { event } = cfg;
            if (event.useLogin === "Y" && event.useLoginKick === "Y") {
                onSendMessage(
                    JSON.stringify({
                        action: "KICK",
                        data: {
                            mode: "KICK",
                            siteEventIdx: iam.siteEventIdx,
                            siteEventMemberIdx: iam.siteEventMemberIdx,
                        },
                    })
                );
            }
        } catch (e) {}
    };

    const onDisconnect = () => {
        setIsConnected(false);
        addDebugMessage(":: Socket Disconnect!");
        onDisconnected && onDisconnected();
    };

    const onMessageReceived = (data) => {
        if (data === "OK") {
            return;
        }
        const isLocal = _CFG.isLocal();
        try {
            const msg = JSON.parse(data);
            const mode = isLocal ? msg.data?.mode : msg.mode;
            switch (mode) {
                case "FEEDBACK":
                    swal({ title: "알림", text: "피드백에 참여해 주세요.", buttons: ["취소", "참여"] }).then((r) => {
                        if (r) {
                            window.location = "#/feedback";
                        }
                    });
                    break;
                case "NOTICE":
                    swal({ title: "알림", text: isLocal ? msg.data?.message : msg.message });
                    break;
                case "DIRECT-MESSAGE":
                    swal({ title: "관리자 메세지", text: isLocal ? msg.data?.message : msg.message });
                    break;
                case "KICK":
                    swal({ title: "알림", text: "다른 장비에서 로그인하셨습니다." }).then(() => {
                        _CK.clearAuth();
                        saveAuth("");
                    });
                    break;
                case "REFRESH":
                    const evt = isLocal ? msg.data?.event : msg.event;
                    if (iam?.siteEventIdx === parseInt(evt)) {
                        window.location.reload();
                    }
                    break;
                case "EMERGENCY":
                    swal({ title: "긴급공지", text: isLocal ? msg.data?.message : msg.message });
                    break;
            }
        } catch (e) {
            console.log(e);
        }
        addDebugMessage(data);
        onMessage && onMessage(data);
        try {
            const d = JSON.parse(data);
            _U.postMessage("SOCKET-RECEIVED", JSON.stringify(isLocal ? d.data : d));
        } catch (e) {}
    };

    const addDebugMessage = (message) => {
        if (debug) {
            setDebugMessages((o) => [message, ...o]);
        }
    };

    const onSendMessage = (message) => {
        try {
            socket?.current?.sendMessage(message);
        } catch (e) {}
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "SOCKET-SEND":
                    onSendMessage(JSON.stringify(data.data));
                    break;
            }
        });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        const timer = setInterval(() => {
            onSendMessage(JSON.stringify({ action: "PING" }));
        }, 30000);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
            clearInterval(timer);
        };
    }, []);

    return (
        <Container debug={debug}>
            <WebSocket ref={socket} url={_CFG.wsHost()} onOpen={onConnect} onClose={onDisconnect} onMessage={onMessageReceived} debug={debug} />
            {debug && <DebugForm isConnected={isConnected} messages={debugMessages} onSend={onSendMessage} />}
        </Container>
    );
};

const DebugForm = (props) => {
    const { messages, onSend, isConnected } = props;
    const [action, setAction] = useState(_OPTIONS[0].action);
    const [message, setMessage] = useState("");
    const onSendClick = () => {
        const form = { action, data: { mode: "NOTICE", message } };
        onSend && onSend(JSON.stringify(form));
        setMessage("");
    };
    return (
        <Fragment>
            <h4>WebSocket Debug - {isConnected ? "연결됨" : "연결안됨"}</h4>
            <div className="row mt-3">
                <div className="col-4">
                    <select value={action} onChange={(e) => setAction(e.target.value)} className="form-control">
                        {_OPTIONS.map((item, i) => (
                            <option key={i} value={item.action}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-8">
                    <div className="input-group">
                        <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" />
                        <div className="input-group-append">
                            <button type="button" className="btn btn-primary" onClick={onSendClick}>
                                <i className="fa fa-send"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <textarea className="form-control" readOnly value={messages?.join("\n")} rows={10} />
                </div>
            </div>
        </Fragment>
    );
};

const Container = styled.div`
    ${(props) =>
        props.debug
            ? `
                position:fixed;
                padding:1rem;
                z-index:10000;
                bottom:1rem; right:1rem;
                width:30rem;
                background:#ffffff;
                border:black solid 1px;
            `
            : `
                display:none;
            `}

    textarea {
        font-size: 0.8rem;
        font-weight: normal;
        color: black;
    }
`;
