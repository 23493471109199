import React, { Fragment, useEffect, useContext, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";

import { Content, Wrapper as Container } from "./Components";

export default (props) => {
    const { cfg } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { idx: siteEventIdx } = cfg.event;

    const [date, setDate] = useState([]);

    const loadData = () => {
        _API.get({
            path: "/v1/site/event/sessions",
            data: {
                siteIdx,
                siteEventIdx,
            },
        }).then((res) => {
            setDate(res.data);
        });
    };

    const getPrincipal = (list) => {
        const fl = list.filter((item) => !!item.principal);
        if (fl.length === 0) {
            return "";
        }
        return fl[0].principal;
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
        // loadData();
    }, []);

    return (
        <Container>
            <Content code="agenda" />
            {/* {date.map((item, i) => (
                <Wrapper key={i}>
                    <Date>
                        {item.date}
                        <Principal dangerouslySetInnerHTML={{ __html: getPrincipal(item.items) }} />
                    </Date>
                    <Session isTitle>
                        <Time className="title">시간</Time>
                        <Info>
                            <Title className="title" isTitle>
                                주제
                            </Title>
                            <Speaker className="title">연자</Speaker>
                        </Info>
                    </Session>
                    {item.items.map((s, j) => (
                        <Session key={j}>
                            <Time>
                                {moment(s.startTime, "HH:mm:ss").format("HH:mm")} ~ {moment(s.endTime, "HH:mm:ss").format("HH:mm")}
                            </Time>
                            <Info>
                                <Title>{s.title}</Title>
                                {s.speaker && <Speaker dangerouslySetInnerHTML={{ __html: s.speaker }} />}
                                {s.location && <Location>장소 : {s.location}</Location>}
                            </Info>
                        </Session>
                    ))}
                </Wrapper>
            ))} */}
        </Container>
    );
};

const Wrapper = styled.div`
    margin: 1rem 0;
    padding: 1rem;
    background: #ffffff;
    border-radius: 0.5rem;

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        padding: 0rem;
        border-radius: none;
    }
`;
const Date = styled.h3`
    position: relative;
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: ${(props) => props.theme.basic.color.primary} solid 1px;
    color: ${(props) => props.theme.basic.color.primary};
    display: block;
`;
const Principal = styled.small`
    position: absolute;
    padding: 0.6rem 2rem;
    right: 0;
    bottom: 0;
    font-weight: normal;
    font-size: 0.8rem;
    background: ${(props) => props.theme.basic.color.primary};
    color: #ffffff;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
`;
const Session = styled.div`
    margin: 0.7rem 0;
    padding-bottom: 0.5rem;
    font-weight: normal;
    font-size: 1rem;
    display: flex;
    border-bottom: ${(props) => props.theme.basic.color.primary} solid 1px;
    .title {
        color: ${(props) => props.theme.basic.color.primary};
        text-align: center;
    }
    span {
        padding: 0.3rem 1rem;
        display: block;
    }

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        ${(props) => (props.isTitle ? `display:none;` : `display:block;`)}
    }
`;
const Time = styled.span`
    flex-basis: 10rem;
    text-align: center;
    color: ${(props) => props.theme.basic.color.primary};

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        text-align: left;
        ::before {
            content: "시간 : ";
            color: ${(props) => props.theme.basic.color.primary};
        }
    }
`;
const Info = styled.div`
    flex: 1;
    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        display: block;
    }
`;
const Title = styled.span`
    flex: 1;
    ${(props) =>
        props.isTitle &&
        `
        border-left: ${props.theme.basic.color.primary} solid 1px;
        border-right: ${props.theme.basic.color.primary} solid 1px;
    `}

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        ::before {
            content: "주제 : ";
            color: ${(props) => props.theme.basic.color.primary};
        }
    }
`;
const Speaker = styled.span`
    flex: 1;

    p,
    div {
        display: inline-block !important;
    }

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        ::before {
            content: "연자 : ";
            color: ${(props) => props.theme.basic.color.primary};
        }
    }
`;
const Location = styled.span`
    text-align: right;

    @media only screen and (max-width: 768px) {
        display: none !important;
    }
`;
