import styled from "styled-components";

import { _API, _U, _CK } from "../../../modules/utils";

export const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: black;
    overflow: auto;
`;
