import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { Content, InputForm, SelectForm, ButtonForm, HRDash, Wrapper, H2 } from "./Components";
import { _API, _U } from "../../../modules/utils";

export default (props) => {
    const { code, history, location, match } = props;

    const { cfg } = useContext(AppContext);
    const [idx, setIdx] = useState(null);
    const [item, setItem] = useState({});

    const sessionCode = "_REGISTRATION_MODIFY";

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");

        if (!location?.pathname.includes("modify")) {
            _U.removeSession(sessionCode);
        }

        const session = _U.getSession(sessionCode);

        if (session) {
            if (session.code === sessionCode && session && session.idx) {
                setIdx(session.idx);
            }
        }
    }, []);

    const loadData = () => {
        if (idx) {
            const data = { idx: idx, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

            _API.get({
                path: "/v1/site/event/registration/item",
                data: data,
            }).then((res) => {
                if (res.error === 0) {
                    setItem(res.data);
                } else {
                    swal(res.message);
                }
            });
        }
    };

    useEffect(() => {
        loadData();
    }, [idx]);

    const onComplete = () => {
        history?.push("/registration/check");
    };

    return (
        <Wrapper>
            <H2>사전등록</H2>
            <Content code="registration" />
            <Form onComplete={onComplete} item={item} />
        </Wrapper>
    );
};

const Form = (props) => {
    const { cfg } = useContext(AppContext);
    const [item, setItem] = useState({});

    useEffect(() => {
        if (props.item) {
            setItem(props.item);
        }
    }, [props.item]);

    const onSetItem = (e) => {
        const target = e.target;
        setItem((o) => {
            return { ...o, [target.name]: target.value };
        });
    };

    const getTeamWareSize = () => {
        let size = cfg.event?.preRegTeamWareSize;

        if (size) {
            size = size.split(",");

            const items = [];
            for (let i in size) {
                items.push({
                    label: size[i],
                    value: size[i],
                });
            }
            return <SelectForm title="팀복 사이즈" name="teamWareSize" nullTitle="선택" items={items} value={item.teamWareSize || ""} onChange={onSetItem} />;
        }

        return "";
    };

    const onSave = () => {
        if (!item.name) {
            swal("성명을 입력하세요.");
            return false;
        }
        if (!item.email) {
            swal("이메일을 입력하세요.");
            return false;
        }
        if (!item.mobile) {
            swal("휴대폰을 입력하세요.");
            return false;
        }

        const data = { ...item, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

        _API.post({
            path: "/v1/site/event/registration",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: item.idx ? `사전등록 수정이 완료 되었습니다.` : `사전등록이 완료 되었습니다.` }).then(() => {
                props.onComplete && props.onComplete();
            });
        });
    };

    const onBack = () => {
        console.log("back");
    };

    return (
        <Fragment>
            <InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} />
            <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} />
            <InputForm title="휴대전화번호" name="mobile" value={item.mobile || ""} onChange={onSetItem} />
            <InputForm title="소속" name="company" value={item.company || ""} onChange={onSetItem} />
            <InputForm title="직책" name="position" value={item.position || ""} onChange={onSetItem} />
            <InputForm title="부서명" name="department" value={item.department || ""} onChange={onSetItem} />
            {getTeamWareSize()}
            <HRDash />
            <ButtonForm title="확인" type="SUBMIT" onClick={onSave} />
            <ButtonForm title="취소" type="RESET" onClick={onBack} />
        </Fragment>
    );
};
