import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import cx from "classnames";

import { AppContext } from "../../../components/AppProvider";

import { Wrapper as Container, Content, InputForm, LocationForm, SelectForm, ButtonForm, InputViewForm, ButtonFormGroup, HRDash, H2 } from "./Components";
import { _API, _U, _CK } from "../../../modules/utils";

export default (props) => {
	const { cfg } = useContext(AppContext);

	const isRegistration = () => {
		if (cfg.event?.preRegStartDate && cfg.event?.preRegEndDate) {
			if (moment(cfg.event?.preRegStartDate) <= moment() && moment(cfg.event?.preRegEndDate) > moment()) {
				return true;
			}
		}
		return false;
	};

	if (!isRegistration() || _CK.isLogin()) {
		return null;
	}

	return (
		<PreWrapper>
			<PreButton to="/registration">
				<i className="fa fa-edit"></i> 사전등록
			</PreButton>
			<PreButton to="/registration/check">
				<i className="fa fa-check"></i> 사전등록확인
			</PreButton>
		</PreWrapper>
	);
};

export const RegView = (props) => {
	const { code, history, location, match } = props;

	const { cfg } = useContext(AppContext);
	const [idx, setIdx] = useState(null);
	const [item, setItem] = useState({});

	const sessionCode = "_REGISTRATION_MODIFY";

	const pointRef = useRef(null);
	const scrollToBottom = () => {
		pointRef?.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(scrollToBottom, []);

	useEffect(() => {
		_U.postMessage("LOCATION-CHANGED");

		if (!location?.pathname.includes("modify")) {
			_U.removeSession(sessionCode);
		}

		const session = _U.getSession(sessionCode);

		if (session) {
			if (session.code === sessionCode && session && session.idx) {
				setIdx(session.idx);
			}
		}
	}, []);

	const loadData = () => {
		if (idx) {
			const data = { idx: idx, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

			_API
				.get({
					path: "/v1/site/event/registration/item",
					data: data,
				})
				.then((res) => {
					if (res.error === 0) {
						setItem(res.data);
					} else {
						swal(res.message);
					}
				});
		}
	};

	useEffect(() => {
		loadData();
	}, [idx]);

	const onComplete = () => {
		history?.push("/registration/check");
	};

	const onCancel = () => {
		// history?.goBack();
		history?.push("/");
	};

	return (
		<Container>
			<Wrapper ref={pointRef}>
				<H2>사전등록</H2>
				<Form onComplete={onComplete} onCancel={onCancel} item={item} />
			</Wrapper>
		</Container>
	);
};

const Form = (props) => {
	const { cfg } = useContext(AppContext);
	const [item, setItem] = useState({});

	useEffect(() => {
		if (props.item) {
			setItem(props.item);
		}
	}, [props.item]);

	const onSetItem = (e) => {
		const target = e.target;
		let value = target.value;

		if (target.name === "mobile") {
			value = value.replace(/-/g, "");
		}
		setItem((o) => {
			return { ...o, [target.name]: value };
		});
	};

	const getTeamWareSize = () => {
		let size = cfg.event?.preRegTeamWareSize;

		if (size) {
			size = size.split(",");

			const items = [];
			for (let i in size) {
				items.push({
					label: size[i],
					value: size[i],
				});
			}
			return <SelectForm title="팀복 사이즈" name="teamWareSize" nullTitle="선택" items={items} value={item.teamWareSize || ""} onChange={onSetItem} />;
		}

		return "";
	};

	const onSave = () => {
		if (!item.name) {
			swal("성명을 입력하세요.");
			return false;
		}
		// if (!item.email) {
		//     swal("이메일을 입력하세요.");
		//     return false;
		// }
		if (!item.mobile) {
			swal("휴대폰을 입력하세요.");
			return false;
		}

		if (!item.passwd) {
			swal("비밀번호를 입력하세요.");
			return false;
		}
		if (!item.passwdRe) {
			swal("비밀번호 확인을 입력하세요.");
			return false;
		}
		if (item.passwd !== item.passwdRe) {
			swal("비밀번호가 일치하지 않습니다.");
			return false;
		}
		if (item.agree !== "Y") {
			swal("개인정보 활용에 동의하여 주세요.");
			return false;
		}
		const data = { ...item, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

		_API
			.post({
				path: "/v1/site/event/registration",
				data: data,
			})
			.then((res) => {
				swal({ title: "알림", text: item.idx ? `사전등록 수정이 완료 되었습니다.` : `사전등록이 완료 되었습니다.` }).then(() => {
					props.onComplete && props.onComplete();
				});
			});
	};

	const onBack = () => {
		props.onCancel && props.onCancel();
	};

	return (
		<Fragment>
			<InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} placeholder="성명을 입력해 주세요" />
			<InputForm title="병원명" name="company" value={item.company || ""} onChange={onSetItem} placeholder="병원명을 입력해 주세요" />
			<InputForm title="진료과" name="department" value={item.department || ""} onChange={onSetItem} placeholder="진료과를 입력해 주세요" />
			<InputForm title="휴대폰번호" name="mobile" value={item.mobile || ""} onChange={onSetItem} placeholder="휴대폰번호 (아이디)" />
			<InputForm title="비밀번호" name="passwd" type="password" value={item.passwd || ""} onChange={onSetItem} placeholder="비밀번호를 입력해 주세요" />
			<InputForm title="비밀번호 확인" name="passwdRe" type="password" value={item.passwdRe || ""} onChange={onSetItem} placeholder="비밀번호 확인을 입력해 주세요" />
			{/* <LocationForm title="지역" name={["sido", "gugun", "dong"]} value={[item.sido, item.gugun, item.dong]} onChange={(v) => setItem({ ...item, ...v })} placeholder={["시,도", "군,구", "동"]} /> */}
			{getTeamWareSize()}
			<HRDash />

			<Content code="registration" />

			<HRDash />
			<div style={{ textAlign: "center" }}>
				<label>
					<input type="radio" name="agree" value="Y" onChange={onSetItem} /> 개인정보 수집 및 이용에 동의함
				</label>
				<label style={{ marginLeft: "1rem" }}>
					<input type="radio" name="agree" value="N" onChange={onSetItem} />
					동의안함
				</label>
			</div>
			<div style={{ fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.8rem", lineHeight: "1rem" }}>
				상단에 게시된 회사의 개인정보수집 및 이용에 관한 설명을 모두 이해하였고, 이에 동의하시면 "동의함"에 체크하여 주시기 바랍니다.
			</div>
			<HRDash />
			<ButtonFormGroup>
				<ButtonForm primary title="확인" type="SUBMIT" onClick={onSave} />
				<ButtonForm title="취소" type="RESET" onClick={onBack} />
			</ButtonFormGroup>
		</Fragment>
	);
};

export const RegCheckView = (props) => {
	const { code, history } = props;
	const { cfg } = useContext(AppContext);

	const [item, setItem] = useState({});

	const pointRef = useRef(null);
	const scrollToBottom = () => {
		pointRef?.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(scrollToBottom, []);

	const onSetItem = (e) => {
		const target = e.target;
		setItem((o) => {
			return { ...o, [target.name]: target.value };
		});
	};

	const onBack = () => {
		history?.push("/");
	};

	const onSave = () => {
		if (!item.name) {
			swal("성명을 입력하세요.");
			return false;
		}
		// if (!item.email) {
		//     swal("이메일을 입력하세요.");
		//     return false;
		// }
		if (!item.mobile) {
			swal("휴대폰을 입력하세요.");
			return false;
		}

		item.type = "WITH-OUT-EMAIL";

		const data = { ...item, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

		_API
			.get({
				path: "/v1/site/event/registration/check",
				data: data,
			})
			.then((res) => {
				if (res.error === 0) {
					// window.sessionStorage.setItem("_REGISTRATION_CHECK", res.data);
					_U.setSession("_REGISTRATION_CHECK", res.data);
					history?.push("/registration/view");
				} else {
					swal(res.message);
				}
			});
	};

	useEffect(() => {
		_U.postMessage("LOCATION-CHANGED");
	}, []);

	return (
		<Container>
			<Wrapper ref={pointRef}>
				<H2>사전등록 조회</H2>
				<InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} placeholder="사전등록시 입력한 성명을 입력하세요." />
				{/* <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} placeholder="사전등록시 입력한 이메일을 입력하세요." /> */}
				<InputForm title="휴대전화번호" name="mobile" value={item.mobile || ""} onChange={onSetItem} placeholder="사전등록시 입력한 휴대전화번호를 입력하세요." />
				<HRDash />
				<ButtonFormGroup>
					<ButtonForm primary title="확인" type="SUBMIT" onClick={onSave} />
					<ButtonForm title="취소" type="RESET" onClick={onBack} />
				</ButtonFormGroup>
			</Wrapper>
		</Container>
	);
};

export const RegDetailView = (props) => {
	const { code, history } = props;
	const { cfg } = useContext(AppContext);
	const [idx, setIdx] = useState(null);
	const [item, setItem] = useState({});

	const sessionCode = "_REGISTRATION_CHECK";

	const pointRef = useRef(null);
	const scrollToBottom = () => {
		pointRef?.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(scrollToBottom, []);

	useEffect(() => {
		const session = _U.getSession(sessionCode);
		if (!session) {
			onBack();
			return;
		}

		if (session.code !== sessionCode || !session || !session.idx) {
			onBack();
			return;
		}

		setIdx(session.idx);
	}, []);

	const loadData = () => {
		if (idx) {
			const data = { idx: idx, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

			_API
				.get({
					path: "/v1/site/event/registration/item",
					data: data,
				})
				.then((res) => {
					if (res.error === 0) {
						setItem(res.data);
					} else {
						swal(res.message);
					}
				});
		}
	};

	useEffect(() => {
		_U.postMessage("LOCATION-CHANGED");

		loadData();
	}, [idx]);

	const onBack = () => {
		history?.push("/");
	};

	return (
		<Container>
			<Wrapper ref={pointRef}>
				<H2>사전등록 내역</H2>
				<InputViewForm title="성명" value={item?.name} />
				{/* <InputViewForm title="이메일" value={item?.email} /> */}
				<InputViewForm title="휴대전화번호" value={item?.mobile} />
				<InputViewForm title="소속" value={item?.company} />
				{/* <InputViewForm title="직책" value={item?.position} /> */}
				<InputViewForm title="진료과" value={item?.department} />
				{item?.teamWareSize && <InputViewForm title="팀복사이즈" value={item?.teamWareSize} />}
				<HRDash />
				<ButtonFormGroup>
					<ButtonForm
						title="수정"
						type="SUBMIT"
						onClick={() => {
							_U.setSession("_REGISTRATION_MODIFY", { code: "_REGISTRATION_MODIFY", idx: idx });
							history?.push("/registration/modify");
						}}
						primary
					/>
					<ButtonForm title="취소" type="RESET" onClick={onBack} />
				</ButtonFormGroup>
			</Wrapper>
		</Container>
	);
};

/* Styled Components ****************************/
const PreWrapper = styled.div`
	display: flex;
	margin-top: 1rem;
	a:nth-child(odd) {
		margin-right: 0.5rem;
	}
	a:nth-child(even) {
		margin-left: 0.5rem;
	}

	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		margin: 1rem;
	}
`;
const PreButton = styled(Link)`
	width: 100%;
	height: 3.125rem;
	font-size: 0.8rem;
	font-weight: normal;
	text-align: center;
	line-height: 3.125rem;

	border-radius: 1.625rem;
	color: #333333;
	border: #ffffff solid 1px;
	background: #ffffff;
	box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.25);
`;

const Wrapper = styled.div`
	width: 70%;
	margin: 1rem auto;
	padding: 1rem;
	background: #ffffff;
	border-radius: 0.5rem;

	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		width: 100%;
		margin: 0rem;
		padding: 0rem;
		border-radius: none;
	}
`;
