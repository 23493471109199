import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { InputViewForm, ButtonForm, HRDash, Wrapper, H2 } from "./Components";
import { _API, _U } from "../../../modules/utils";

export default (props) => {
    const { code, history } = props;
    const { cfg } = useContext(AppContext);
    const [idx, setIdx] = useState(null);
    const [item, setItem] = useState({});

    const sessionCode = "_REGISTRATION_CHECK";

    useEffect(() => {
        const session = _U.getSession(sessionCode);
        if (!session) {
            onBack();
            return;
        }

        if (session.code !== sessionCode || !session || !session.idx) {
            onBack();
            return;
        }

        setIdx(session.idx);
    }, []);

    const loadData = () => {
        if (idx) {
            const data = { idx: idx, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

            _API.get({
                path: "/v1/site/event/registration/item",
                data: data,
            }).then((res) => {
                if (res.error === 0) {
                    setItem(res.data);
                } else {
                    swal(res.message);
                }
            });
        }
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");

        loadData();
    }, [idx]);

    const onBack = () => {
        history?.push("/registration/check");
    };

    return (
        <Wrapper>
            <H2>사전등록 내역</H2>
            <InputViewForm title="성명" value={item?.name} />
            <InputViewForm title="이메일" value={item?.email} />
            <InputViewForm title="휴대전화번호" value={item?.mobile} />
            <InputViewForm title="소속" value={item?.company} />
            <InputViewForm title="직책" value={item?.position} />
            <InputViewForm title="부서명" value={item?.department} />
            {item?.teamWareSize && <InputViewForm title="팀복사이즈" value={item?.teamWareSize} />}
            <HRDash />
            <ButtonForm
                title="수정"
                type="SUBMIT"
                onClick={() => {
                    _U.setSession("_REGISTRATION_MODIFY", { code: "_REGISTRATION_MODIFY", idx: idx });
                    history?.push("/registration/modify");
                }}
            />
            <ButtonForm title="취소" type="RESET" onClick={onBack} />
        </Wrapper>
    );
};
