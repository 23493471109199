import React, { Fragment, useEffect, useContext, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";

import { Content } from "./Components";

export default (props) => {
    const { cfg } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { idx: siteEventIdx } = cfg.event;

    const [date, setDate] = useState([]);

    const loadData = () => {
        _API.get({path:"/v1/site/event/sessions", data:{
            siteIdx, siteEventIdx
        }}).then(res=>{
            setDate(res.data);
        })
    }

    const getPrincipal = (list) => {
        const fl = list.filter(item=>!!item.principal);
        if (fl.length === 0) {
            return '';
        }
        return fl[0].principal;
    }

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
        loadData()
    }, []);

    return (
        <Fragment>
            <Content code="agenda" />
            {date.map((item, i)=>(
                <Wrapper key={i}>
                    <Date>
                        {item.date}
                        <Principal dangerouslySetInnerHTML={{__html: getPrincipal(item.items)}} />
                    </Date>
                    {item.items.map((s, j)=>(
                        <Session key={j}>
                            <Time>
                                {moment(s.startTime, "HH:mm:ss").format("HH:mm")} ~ {moment(s.endTime, "HH:mm:ss").format("HH:mm")}
                            </Time>
                            <Info>
                                <Title>
                                    {s.title}
                                </Title>
                                {s.speaker && (
                                    <Speaker>
                                        연자 : <span dangerouslySetInnerHTML={{__html:s.speaker}}/>
                                    </Speaker>
                                )}
                                {s.location && (
                                    <Location>
                                        장소 : {s.location}
                                    </Location>
                                )}
                                
                            </Info>
                        </Session>
                    ))}
                </Wrapper>
            ))}
        </Fragment>
    );
};

const Wrapper = styled.div`
    margin:1rem 0 0;
`;
const Date = styled.h3`
    padding-bottom:0.5rem;
    font-size:1rem;
    font-weight:bold;
    border-bottom:#cccccc solid 1px;
`
const Principal = styled.small`
    font-weight:normal;
    font-size:0.8rem;
    float:right;
    clear:right;
`
const Session = styled.div`
    margin:0.7rem 0;
    padding-bottom:0.5rem;
    font-weight:normal;
    font-size:0.9rem;
    display:flex;
    border-bottom:#cccccc solid 1px;
`
const Time = styled.span`
    flex-basis:8rem;
    
`;
const Info = styled.div`
    flex:1;
    span {
        display:block;
    }
`
const Title = styled.span`
`;
const Speaker = styled.span`
`;
const Location = styled.span`
    text-align:right;

    @media only screen and (max-width: 768px) {
        display:none !important;
    }
`;