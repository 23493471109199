import _CK from "../utils/cookie";
import _U from "../utils/utilities";

const urlProd = "dmon9.com";
const urlTest = "test.dmon9.com";

const config = {
    apiKey: "#UE7&CZ[YDABcrGW<JT!t9*e",
};
config.apiHost = () => {
    const { hostname } = window.location;
    if (hostname.startsWith("local")) {
        return "http://localhost:3100";
    } else if (hostname.startsWith("test")) {
        return "https://api-test.dmon9.com";
    } else {
        return "https://api.dmon9.com";
    }
};
config.isLocal = () => {
    const { hostname } = window.location;
    if (hostname.startsWith("local")) {
        return true;
    } else {
        return false;
    }
};
config.wsHost = () => {
    const authors = {
        apikey: config.apiKey,
    };
    if (_CK.isLogin()) {
        authors.authorization = _CK.getAuth();
    }

    const { hostname } = window.location;
    if (hostname.startsWith("local")) {
        return `ws://localhost:3300?auth=${_U.encode(authors)}`;
    } else if (hostname.startsWith("test")) {
        return `wss://ws-test.dmon9.com?auth=${_U.encode(authors)}`;
    } else {
        return `wss://ws.dmon9.com?auth=${_U.encode(authors)}`;
    }
};
config.google = {
    maps: {
        apiKey: "AIzaSyByU4xgpSzrDltOxZQPCRCrml94ZtiXyow",
    },
};

export default config;
