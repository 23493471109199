import React, { Fragment, useContext, useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";

import { AppContext } from "../../../components/AppProvider";
import { _API, _CK, _U } from "../../../modules/utils";

import { Content } from "./Components";
import Login from "./Components.Login";

export default (props) => {
    const { cfg } = useContext(AppContext);
    const isTerm = isFeedbackTerm(cfg);

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
    }, []);

    return (
        <Fragment>
            <Content code="feedback" />
            {isTerm.status ? <Feedback {...isTerm} /> : <TermGuide>피드백 기간이 아닙니다.</TermGuide>}
        </Fragment>
    );
};

const Feedback = (props) => {
    const { category, login } = props;
    const { cfg, auth } = useContext(AppContext);
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        setIsLogin(_CK.isLogin());
    }, [auth]);

    if (login && !isLogin) {
        return (
            <LoginGuide>
                <h3>로그인이 필요합니다.</h3>
                <Login />
            </LoginGuide>
        );
    }

    return <FeedbackForm category={category} />;
};

const FeedbackForm = (props) => {
    const { category } = props;
    const { cfg } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { idx: siteEventIdx } = cfg.event;

    const [isLoaded, setIsLoaded] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);

    const loadData = () => {
        _API.get({
            path: "/v1/site/event/feedback",
            data: {
                siteIdx,
                siteEventIdx,
                category,
            },
        }).then((res) => {
            const { data: questions } = res;
            const as = [];
            questions.map((q) => {
                as.push({
                    siteEventFeedbackIdx: q.idx,
                    answer1: "N",
                    answer2: "N",
                    answer3: "N",
                    answer4: "N",
                    answer5: "N",
                    answerDtl: "",
                });
            });
            setQuestions(questions);
            setAnswers(as);
            setIsLoaded(true);
        });
    };

    const onAnswerClick = (index, answerNumber) => {
        const q = questions[index];
        const as = [...answers];
        const a = as[index];
        if (q.type === "MULTIPLE") {
            a[`answer${answerNumber}`] = a[`answer${answerNumber}`] === "Y" ? "N" : "Y";
        } else {
            a.answer1 = answerNumber === 1 ? "Y" : "N";
            a.answer2 = answerNumber === 2 ? "Y" : "N";
            a.answer3 = answerNumber === 3 ? "Y" : "N";
            a.answer4 = answerNumber === 4 ? "Y" : "N";
            a.answer5 = answerNumber === 5 ? "Y" : "N";
        }
        setAnswers(as);
    };

    const onAnswerDtlChange = (index, e) => {
        const as = [...answers];
        const a = as[index];
        a.answerDtl = e.target.value;
        setAnswers(as);
    };

    const onSave = () => {
        _API.post({
            path: "/v1/site/event/feedback",
            data: {
                siteIdx,
                siteEventIdx,
                answers,
            },
        }).then((res) => {
            swal({ title: "알림", text: "피드백에 참여해 주셔서 감사합니다." }).then(() => {
                document.location.href = "#/";
            });
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    if (!isLoaded) {
        return null;
    }

    return (
        <FBWrapper>
            <FBTitle>
                항목 <small>모든 항목을 입력해 주세요.</small>
            </FBTitle>
            {questions.map((item, i) => (
                <FBItem key={i}>
                    {item.top && <FBItemTop dangerouslySetInnerHTML={{ __html: item.top }} />}
                    {item.question && <FBItemQuestion dangerouslySetInnerHTML={{ __html: item.question }} />}
                    {item.type === "ANSWER" ? (
                        <FBItemAnswerDtl>
                            <textarea value={answers[i]?.answerDtl} onChange={onAnswerDtlChange.bind(this, i)} />
                        </FBItemAnswerDtl>
                    ) : (
                        <Fragment>
                            {item.answer1 && <FBItemAnswer selected={answers[i]?.answer1 === "Y"} onClick={onAnswerClick.bind(this, i, 1)} dangerouslySetInnerHTML={{ __html: item.answer1 }} />}
                            {item.answer2 && <FBItemAnswer selected={answers[i]?.answer2 === "Y"} onClick={onAnswerClick.bind(this, i, 2)} dangerouslySetInnerHTML={{ __html: item.answer2 }} />}
                            {item.answer3 && <FBItemAnswer selected={answers[i]?.answer3 === "Y"} onClick={onAnswerClick.bind(this, i, 3)} dangerouslySetInnerHTML={{ __html: item.answer3 }} />}
                            {item.answer4 && <FBItemAnswer selected={answers[i]?.answer4 === "Y"} onClick={onAnswerClick.bind(this, i, 4)} dangerouslySetInnerHTML={{ __html: item.answer4 }} />}
                            {item.answer5 && <FBItemAnswer selected={answers[i]?.answer5 === "Y"} onClick={onAnswerClick.bind(this, i, 5)} dangerouslySetInnerHTML={{ __html: item.answer5 }} />}
                        </Fragment>
                    )}
                    {item.bottom && <FBItemBottom dangerouslySetInnerHTML={{ __html: item.bottom }} />}
                </FBItem>
            ))}
            <FBSave onClick={onSave}>저장</FBSave>
        </FBWrapper>
    );
};

const TermGuide = styled.div``;
const LoginGuide = styled.div``;
const FBWrapper = styled.div``;
const FBTitle = styled.h3``;
const FBItem = styled.div`
    margin: 0 1rem 1rem;
    border: black solid 1px;
`;
const FBItemTop = styled.div`
    margin: 0.6rem;
`;
const FBItemQuestion = styled.div`
    margin: 0.6rem;
`;
const FBItemAnswer = styled.div`
    margin: 1rem;
    padding: 0.5rem 1rem;
    border: ${(props) => (props.selected ? "blue" : "gray")} solid 1px;
    cursor: pointer;
`;
const FBItemAnswerDtl = styled.div`
    margin: 1rem;
    textarea {
        width: 100%;
        height: 5rem;
        padding: 1rem;
    }
`;
const FBItemBottom = styled.div`
    margin: 0.6rem;
`;
const FBSave = styled.button``;

const isFeedbackTerm = (cfg) => {
    if (!cfg || !cfg.event) {
        return { status: false };
    }

    const { feedbackBeforeStartDate: bStart, feedbackBeforeEndDate: bEnd, feedbackAfterStartDate: aStart, feedbackAfterEndDate: aEnd } = cfg.event;
    const { feedbackBeforeUseLogin: bLogin, feedbackAfterUseLogin: aLogin } = cfg.event;
    const now = moment();
    if (bStart && bEnd) {
        const bs = moment(bStart);
        const be = moment(bEnd);
        const bCheck = now.isBetween(bs, be);
        if (bCheck) {
            return { status: true, category: "BEFORE", login: bLogin === "Y" };
        }
    }

    if (aStart && aEnd) {
        const as = moment(aStart);
        const ae = moment(aEnd);
        const aCheck = now.isBetween(as, ae);
        if (aCheck) {
            return { status: true, category: "AFTER", login: aLogin === "Y" };
        }
    }

    return { status: false };
};
