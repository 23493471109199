import React, { useContext, useEffect } from "react";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { AppContext } from "../components/AppProvider";
import { _CK } from "../modules/utils";

import Root from "./Root";
import { TplBasicViews, TplWebinarSingleViews, TplBoothOnlyViews, TplWebinarSingleLoginPasswordViews, TplWebinarSingleModalViews } from "./template";

export default (props) => {
    const { auth, cfg } = useContext(AppContext);

    useEffect(() => {}, [auth]);

    if (cfg) {
        const { event } = cfg;
        if (event) {
            switch (event.uiTemplate) {
                case "WEBINAR-SINGLE-ROOM":
                    return <TplWebinarSingleViews />;
                case "WEBINAR-LOGIN-PASSWORD":
                    return <TplWebinarSingleLoginPasswordViews />;
                case "WEBINAR-MODAL-SIGNLE-ROOM":
                    return <TplWebinarSingleModalViews />;
                case "BOOTH-ONLY":
                    return <TplBoothOnlyViews />;
                default:
                    // Basic & Others
                    return <TplBasicViews />;
            }
        }
    }
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Root} />
                <Redirect path="*" to="/" />
            </Switch>
        </Router>
    );
};
