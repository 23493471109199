import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { Container } from "./Components";

import { default as BoothView, Booth as BoothDetailView } from "./Booth";

export default (props) => {
    return (
        <Container>
            <Router>
                <Switch>
                    <Route path="/booth" exact component={BoothView} />
                    <Route path="/booth/:code" component={BoothDetailView} />
                </Switch>
            </Router>
        </Container>
    );
};
