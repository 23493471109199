import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";

import { _U, _CK } from "../../../modules/utils";
import { _CFG } from "../../../modules/config";

import Socket from "../../../components/UI/Socket";
import { Content } from "./Components";
import Login from "./Components.Login";
import Enter from "./Components.Enter";
import Video from "../../../components/UI/Video";

import { default as AgendaView } from "./Agenda";
import { default as LocationView } from "./Location";
import { default as FeedbackView } from "./Feedback";
import { default as RegistrationView } from "./Registration";
import { default as RegistrationCheckView } from "./RegistrationCheck";
import { default as RegistrationDetailView } from "./RegistrationDetail";
import { default as SessionView } from "./Session";
import { default as BoothView, Booth as BoothDetailView } from "./Booth";

export default (props) => {
    const { cfg, auth } = useContext(AppContext);
    const event = cfg.event || null;

    useEffect(() => {
        if (event) {
            setBackground(event, cfg.cfUrl);
        }
        return () => {
            clearBackground();
        };
    }, []);

    return (
        <Router>
            <Header />
            <Navigation />
            <Container>
                <Switch>
                    <Route path="/" exact component={RootView} />
                    <Route path="/agenda" component={AgendaView} />
                    <Route path="/location" component={LocationView} />
                    <Route path="/feedback" component={FeedbackView} />
                    <Route path="/registration/check" component={RegistrationCheckView} />
                    <Route path="/registration/view" component={RegistrationDetailView} />
                    <Route path="/registration/modify" component={RegistrationView} />
                    <Route path="/registration" component={RegistrationView} />
                    <Route path="/session/:location" component={SessionView} />
                    <Route path="/booth" exact component={BoothView} />
                    <Route path="/booth/:code" component={BoothDetailView} />
                    <Route path="/:code" component={ContentView} />
                    <Redirect path="*" to="/" />
                </Switch>
            </Container>
            {(auth || cfg.event?.useLogin !== "Y") && <Socket />}
        </Router>
    );
};

const RootView = (props) => {
    const { cfg } = useContext(AppContext);

    const isRegistration = () => {
        if (cfg.event?.preRegStartDate && cfg.event?.preRegEndDate) {
            if (moment(cfg.event?.preRegStartDate) <= moment() && moment(cfg.event?.preRegEndDate) > moment()) {
                return true;
            }
        }

        return false;
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
    }, []);

    return (
        <Fragment>
            <MainVisual />
            <RBody>
                <RSide>
                    {isRegistration() && (
                        <RRWrapper>
                            <RRButton to="/registration">
                                <i className="fa fa-edit"></i> 사전등록
                            </RRButton>
                            <RRButton to="/registration/check">
                                <i className="fa fa-check"></i> 사전등록확인
                            </RRButton>
                        </RRWrapper>
                    )}
                    <Login />
                    <Enter />
                </RSide>
                <RContent>
                    <Content code="home" />
                </RContent>
            </RBody>

            {/* {cfg.event && (
                <IndexMenu>
                    {cfg.event.menu?.map((item, i) => (
                        <IndexMenuItem key={i}>
                            <Link to={`/${item.value}`}>
                                {item.label} <i className="fa fa-chevron-right"></i>
                            </Link>
                        </IndexMenuItem>
                    ))}
                </IndexMenu>
            )} */}
            <RCopyright>Copyright © 2020...</RCopyright>
        </Fragment>
    );
};

const MainVisual = (props) => {
    const { cfg } = useContext(AppContext);
    if (!cfg.event) {
        return null;
    }
    const { cfUrl: url, event } = cfg;
    if (isMobile) {
        if (!event.uiVisualMobileFilePath) {
            return null;
        }
    } else {
        if (!event.uiVisualPcFilePath) {
            return null;
        }
    }
    const teaser = event?.uiTeaserUrl;

    return <MVWrapper>{teaser ? <Fragment>{teaser.indexOf("m3u8") !== 0 ? <Video url={teaser} autoPlay={true} loop={true} poster={isMobile ? `${url}${event.uiVisualMobileFilePath}` : `${url}${event.uiVisualPcFilePath}`} /> : <iframe src={teaser} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />}</Fragment> : <MVImg src={isMobile ? `${url}${event.uiVisualMobileFilePath}` : `${url}${event.uiVisualPcFilePath}`} />}</MVWrapper>;
};

const ContentView = (props) => {
    const { code } = props.match.params;
    return <Content code={code} />;
};

const Header = (props) => {
    const { cfg } = useContext(AppContext);
    const { cfUrl, event } = cfg;
    return (
        <HWrapper bg={event?.uiBgColor}>
            <HContainer>
                <Link to="/">{cfUrl && event?.uiLogoPcFilePath ? <img src={`${cfUrl}${event.uiLogoPcFilePath}`} alt={event.title} /> : event.title}</Link>
            </HContainer>
        </HWrapper>
    );
};

const Navigation = (props) => {
    const { cfg } = useContext(AppContext);
    const { event } = cfg;

    const [menu, setMenu] = useState("");

    const activeMenu = () => {
        const { location } = window;
        if (!location) {
            return;
        }
        const { hash } = location;
        if (!hash) {
            return;
        }
        setMenu(hash.replace("#/", ""));
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "LOCATION-CHANGED":
                    activeMenu();
                    break;
            }
        });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [location]);

    if (event?.menu?.length === 0) {
        return null;
    }

    return (
        <NWrapper bg={event?.uiBgColor} /*isRoot={!menu}*/>
            <NContainer>
                <NItem isHome>
                    <Link to="/" className={cx({ active: !menu })}>
                        HOME
                    </Link>
                </NItem>
                {event?.menu?.map((item, i) => (
                    <NItem key={i}>
                        <Link to={`/${item.value}`} className={cx({ active: item.value === menu })}>
                            {item.label}
                        </Link>
                    </NItem>
                ))}
            </NContainer>
        </NWrapper>
    );
};

/**** Styled Components *****************************************/

const Container = styled.div`
    margin: 0 auto;
    padding: 0.5rem 0;
    width: ${(props) => props.theme.basic.width}px;

    @media only screen and (max-width: 768px) {
        margin: 4.06rem 0 3.14rem;
        padding: 1.25rem;
        width: 100%;
    }
`;

const HWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 4.06rem;
    overflow: hidden;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${(props) => (props.bg ? `background:${props.bg};` : "")}

    @media only screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
`;

const HContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: ${(props) => props.theme.basic.width}px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        max-width: 100%;
    }

    a {
        width: 100%;
        height: 100%;
        display: block;
        color: ${(props) => props.theme.color.primary};
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const NWrapper = styled.div`
    height: 3.14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-top: #e3e3e3 solid 1px;
    border-bottom: #e3e3e3 solid 1px;

    ${(props) => (props.bg ? `background:${props.bg};` : "")}

    @media only screen and (min-width: 769px) {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
    }

    @media only screen and (max-width: 768px) {
        ${(props) => (props.isRoot ? "display:none;" : "")};
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: none;
    }
`;

const NContainer = styled.ul`
    list-style: none;
    width: ${(props) => props.theme.basic.width}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NItem = styled.li`
    height: 3rem;
    display: "inline-block";
    flex: 1;
    text-align: center;

    @media only screen and (max-width: 768px) {
        display: ${(props) => (props.isHome ? "none" : "inline-block")};
    }

    a {
        display: block;
        line-height: 3rem;
        width: 100%;
        height: 100%;
        color: #333333;
    }
    a:hover {
        border-bottom: #333333 solid 3px;
    }
    a.active {
        border-bottom: #333333 solid 3px;
    }
`;

const IndexMenu = styled.ul`
    display: none;
    @media only screen and (max-width: 768px) {
        display: block;
    }
`;
const IndexMenuItem = styled.li`
    position: relative;
    margin-bottom: 0.93rem;
    background: #ffffff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.14);
    border-radius: 0.5rem;
    a {
        padding: 0.93rem 1.25rem;
        display: block;
        color: ${(props) => props.theme.color.primary};
    }
    i {
        position: absolute;
        right: 0.93rem;
    }
`;

const RBody = styled.div`
    position: relative;
    display: flex;
    flex-direction: row-reverse;

    @media only screen and (max-width: 768px) {
        display: block;
    }
`;
const RContent = styled.div`
    flex: 1;
    @media only screen and (max-width: 768px) {
        margin: 1rem 0;
    }
`;
const RSide = styled.div`
    flex-basis: 16rem;
    @media only screen and (max-width: 768px) {
        margin: 1rem 0;
    }
`;

const RCopyright = styled.div`
    margin: 1rem;
    text-align: center;
`;

const RRWrapper = styled.div`
    margin: 0 0 1rem;
    display: flex;
    justify-content: space-between;

    a:nth-child(odd) {
        margin-right: 0.25rem;
    }
    a:nth-child(even) {
        margin-left: 0.25rem;
    }
`;
const RRButton = styled(Link)`
    padding: 0.65rem 1rem;
    font-size: 0.8rem;
    font-weight: normal;
    background: #ffffff;
    color: ${(props) => props.theme.color.primary};
    border: ${(props) => props.theme.color.secondary} solid 1px;
    border-radius: 0.3rem;
    width: 100%;
    text-align: center;
`;

const MVWrapper = styled.div`
    margin-bottom: 1rem;
`;
const MVImg = styled.img`
    max-width: 100%;
`;

/**** Others *****************************************/

const setBackground = (e, url) => {
    const b = $("body");
    if (e.uiBgColor) {
        b.css("background-color", e.uiBgColor);
    }
    if (isMobile) {
        if (e.uiBgMobileFilePath) {
            b.css("background-image", `url(${url}${e.uiBgMobileFilePath})`);
            b.css("background-repeat", "no-repeat");
            b.css("background-size", "cover");
        }
    } else {
        if (e.uiBgPcFilePath) {
            b.css("background-image", `url(${url}${e.uiBgPcFilePath})`);
            b.css("background-repeat", "no-repeat");
            b.css("background-size", "cover");
        }
    }
};
const clearBackground = () => {
    $("body").css("background", "#ffffff");
    $("body").css("background-image", "none");
};
