import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";

export default (props) => {
    const { auth } = useContext(AppContext);
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        setIsLogin(_CK.isLogin());
    }, [auth]);

    return <LoginWrapper>{isLogin ? <LogOut /> : <LogIn />}</LoginWrapper>;
};

const LogIn = (props) => {
    const { cfg, saveAuth, saveIam } = useContext(AppContext);
    const { event } = cfg;

    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");

    const onRequestCert = () => {
        if (!mobile) {
            swal({ title: "알림", text: "휴대폰 번호를 입력해 주세요." });
            return;
        }
        if (!mobile.startsWith("01") || mobile.length < 10 || mobile.length > 13) {
            swal({ title: "알림", text: "올바른 휴대폰 번호를 입력해 주세요." });
            return;
        }
        if (!password) {
            swal({ title: "알림", text: "비밀번호를 입력해 주세요." });
            return;
        }
        _API.post({
            path: "/v1/site/event/login/password",
            data: {
                siteIdx: cfg.idx,
                siteEventIdx: cfg.event?.idx,
                mobile,
                password,
            },
        }).then((res) => {
            onLogin(res.data);
        });
    };

    const onLogin = (data) => {
        const { accessKey: auth, iam } = data;
        _CK.setAuth(auth);
        saveAuth(auth);
        saveIam(iam);
        swal({ title: "로그인", text: `${iam.name}님 방문해 주셔서 감사합니다.` }).then(() => {});
    };

    useEffect(() => {}, [cfg]);

    return (
        <Container>
            <Title font={event?.uiFontColor}>Sign In</Title>
            <Input type="text" value={mobile} placeholder="휴대폰번호 입력" onChange={(e) => setMobile(e.target.value)} />
            <Input type="password" value={password} placeholder="비밀번호 입력" onChange={(e) => setPassword(e.target.value)} />
            <Button onClick={onRequestCert}>
                <i className="fa fa-sign-in"></i> 로그인
            </Button>
        </Container>
    );
};

const LogOut = (props) => {
    const { saveAuth, saveIam, cfg } = useContext(AppContext);
    const { event } = cfg;

    const onLogout = () => {
        _CK.clearAuth();
        saveAuth(null);
        saveIam(null);
    };

    return (
        <Container>
            <Title font={event?.uiFontColor}>Sign Out</Title>
            <FormGroup>
                <Button block onClick={onLogout}>
                    <i className="fa fa-sign-out"></i> 로그아웃
                </Button>
            </FormGroup>
        </Container>
    );
};
/* Styled Components ***************************************/
const LoginWrapper = styled.div`
    width: 100%;
    display: block;
`;
const Container = styled.div`
    margin: 0;

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        margin: 1rem;
        padding: 1rem;
        background: #ffffff;
        border-radius: 0.5rem;
    }
`;
const Title = styled.h3`
    margin-bottom: 1rem;

    color: ${(props) => (props.font ? props.font : `#3e3e3e`)};
    font-size: 1.5rem;
    line-height: 2rem;
    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        color: #3e3e3e;
    }
`;
const FormGroup = styled.div`
    display: flex;
`;

const Input = styled.input`
    -webkit-appearance: none;
    flex: 1;
    margin-bottom: 1rem;
    padding: 0.8rem 1.25rem;
    font-size: 1.06rem;

    color: #333333;
    width: 100%;
    height: 3.125rem;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 1.625rem;
    // border-top-right-radius: 0rem;
    // border-bottom-right-radius: 0rem;

    & ::placeholder {
        color: #c1c1c1;
    }
`;
const Button = styled.button`
    width: 100%;
    height: 3.125rem;
    font-size: 0.8rem;
    font-weight: normal;

    border-radius: 1.625rem;

    ${(props) =>
        !props.block
            ? `
        flex-basis: 8rem;
        // border-top-left-radius: 0rem;
        // border-bottom-left-radius: 0rem;
        `
            : `
        flex:1;
        `}

    ${(props) =>
        props.dark
            ? `
        color: #ffffff;
        border: #333333 solid 1px;
        background: #333333;    
        box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.25);
    `
            : `
        color: #ffffff;
        border: #1889ea solid 1px;
        background: #1889ea;    
        box-shadow: 0 3px 6px 0 rgba(24, 137, 234, 0.25);
    `}
`;
