import React, { useState, useEffect, useRef } from "react";
import { _CK } from "../modules/utils";

const AmazonIVSWorkaround = (props) => {
    let { url, poster } = props;

    if (_CK.get("stream")) {
        url = _CK.get("stream").streamPlayUrl;
        poster = _CK.get("stream").posterImage;
    }

    const videoEl = useRef(null);
    const [isPlay, setIsPlay] = useState(false);
    const [isEnd, setIsEnd] = useState(true);

    let player = null;
    if (IVSPlayer.isPlayerSupported) {
        player = IVSPlayer.create();
    }

    useEffect(() => {
        player.attachHTMLVideoElement(document.getElementById("video-player"));
        player.load(url);
        player.play();
    }, []);

    // useInterval(() => {
    //     if (player && !isPlay) {
    //         ivsPlay();
    //     }
    // }, 10000);

    // const ivsPlay = async () => {
    //     setTimeout(() => {
    //         if (player.getState() === "Idle") {
    //             player.load(url);
    //         }

    //         if (player.getState() === "Ready" || player.getState() === "Playing") {
    //             player.play();
    //             setIsPlay(true);
    //             setIsEnd(false);
    //         }
    //     }, 2000);
    // };

    return <video id="video-player" ref={videoEl} poster={poster} playsInline autoPlay width="100%" height="100%" controls />;
};

function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export default AmazonIVSWorkaround;
