import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import jquery from "jquery";
import metismenu from "metismenu";
import bootstrap from "bootstrap";

import App from "./App";
ReactDOM.render(<App />, document.getElementById("root"));
