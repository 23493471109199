import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import GoogleMap from "google-map-react";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _CFG } from "../../../modules/config";

import { Content, Wrapper as Container } from "./Components";

export default (props) => {
    const { cfg } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { idx: siteEventIdx } = cfg.event;

    const [list, setList] = useState([]);

    const loadData = () => {
        _API.get({
            path: "/v1/site/event/location",
            data: {
                siteIdx,
                siteEventIdx,
            },
        }).then((res) => {
            setList(res.data);
        });
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
        loadData();
    }, []);

    return (
        <Container>
            {list.length > 0 && (
                <Wrapper>
                    {list.map((item, i) => {
                        const center = {
                            lat: item.latitude,
                            lng: item.longitude,
                        };
                        const zoom = 16;
                        return (
                            <Location key={i}>
                                <Title>{item.title}</Title>
                                {item.latitude && item.longitude && (
                                    <MapWrapper>
                                        <GoogleMap bootstrapURLKeys={{ key: _CFG.google.maps.apiKey }} defaultCenter={center} defaultZoom={zoom}>
                                            <MapAnchor lat={item.latitude} lng={item.longitude}>
                                                <i className="fa fa-map-marker"></i>
                                            </MapAnchor>
                                        </GoogleMap>
                                    </MapWrapper>
                                )}
                                <AddrItem>연락처 : {item.tel}</AddrItem>
                                <AddrItem>
                                    주소 : ({item.zipcode}) {item.addr} {item.addrDtl}
                                </AddrItem>
                                <Transport dangerouslySetInnerHTML={{ __html: item.transport }} />
                            </Location>
                        );
                    })}
                </Wrapper>
            )}

            <Content code="location" />
        </Container>
    );
};

const Wrapper = styled.div``;
const Location = styled.div``;
const Title = styled.h3``;
const AddrItem = styled.p`
    font-size: 0.9rem;
    line-height: 1.2rem;
`;
const MapWrapper = styled.div`
    width: 100%;
    height: 15rem;
    color: red;
    margin: 1rem 0;
`;
const MapAnchor = styled.div``;
const Transport = styled.div``;
