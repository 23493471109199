import React, { useState, useEffect, Fragment } from "react";
import cx from "classnames";
import moment from "moment";
import styled from "styled-components";
import Hls from "hls.js";
import { Player, BigPlayButton, ControlBar, PlayToggle } from "video-react";
import { isIOS } from "react-device-detect";

import { _CFG } from "../../modules/config";
import { _CK, _API } from "../../modules/utils";
import AmazonIVSWorkaround from "../AmazonIVSWorkaround";

import VideoJs from "video.js";
import "video.js/dist/video-js.min.css";

export default class Video extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isMuted: true, iosUrl: "", id: `video${moment().format("YYYYMMDDhhmmss")}`, currentTime: 0 };
    }

    componentDidMount() {
        if (isIOS && !this.isIvs(this.props.url)) {
            this.getIosUrl(this.props.url);
        }
    }

    componentWillReceiveProps(props) {
        // console.log(props.selectVodTime);
        // if (props.selectVodTime) {
        //     this.setState({ currentTime: moment.duration(props.selectVodTime).asSeconds() });
        // }
    }

    isIvs = (streamUrl) => {
        return streamUrl.includes("live-video.net");
    };

    getIosUrl = (url) => {
        _API.getIosUrl({
            path: `${url}/ios`,
        })
            .then((res) => res.data)
            .then((data) => {
                this.setState({ iosUrl: data });
            });
    };

    render() {
        const { url, poster, isLive, selectVodTime } = this.props;
        const { autoPlay, controls, muted, loop, uiOff, uiFeed, fluid, width, height } = this.props;

        let currentTime = 0;
        if (selectVodTime) {
            currentTime = moment.duration(selectVodTime).asSeconds();
        }

        return (
            <>
                {this.isIvs(url) ? (
                    <>
                        <AmazonIVSWorkaround url={url} poster={poster} />
                    </>
                ) : (
                    <>
                        <VideoWrapper id={this.state.id} ref={(ref) => (this.player = ref)} src={`${url}?apikey=${encodeURIComponent(_CFG.apiKey)}`} className="video-js" controls preload="auto" poster={poster} width={width} height={height} playsInline autoPlay={autoPlay} muted={!autoPlay} loop={loop} fluid={fluid}>
                            <VideoSource id={this.state.id} src={url} type="application/x-mpegURL" autoPlay={autoPlay} muted={!autoPlay} loop={loop} currentTime={currentTime} />
                        </VideoWrapper>
                        {/* {isIOS && this.state.iosUrl ? (
                            <Player ref={(ref) => (this.player = ref)} src={this.state.iosUrl} uiOff={uiOff} width={width} height={height} poster={poster} autoPlay={autoPlay} muted={muted} loop={loop} fluid={fluid} playsInline>
                                <ControlBar autoHide={true} disableDefaultControls={false} className={cx({ hidden: !controls })}>
                                    <PlayToggle />
                                </ControlBar>
                                <BigPlayButton position="center" />
                            </Player>
                        ) : (
                            <Player ref={(ref) => (this.player = ref)} uiOff={uiOff} width={width} height={height} poster={poster} autoPlay={autoPlay} muted={muted} loop={loop} fluid={fluid} playsInline>
                                <HLSSource isVideoChild src={url} withCredentials autoPlay={autoPlay} muted={autoPlay} isLive={isLive} />
                                <ControlBar autoHide={true} disableDefaultControls={false} className={cx({ hidden: !controls })}>
                                    <PlayToggle />
                                </ControlBar>
                                <BigPlayButton position="center" />
                            </Player>
                        )} */}
                    </>
                )}
            </>
        );
    }
}

const VideoWrapper = styled.video`
    padding-top: 56.25%;
    width: 100%;
    position: relative;

    .vjs-big-play-button {
        top: 50%;
        left: 50%;
        margin-top: -0.75em;
        margin-left: -1.5em;
    }
`;

const VideoSource = (props) => {
    const { autoPlay, id, currentTime } = props;

    useEffect(() => {
        const player = VideoJs(id);
        if (autoPlay) {
            player.autoplay("muted");
        }

        if (currentTime > 0) {
            player.currentTime(currentTime);
        }
    }, [props.src, currentTime]);

    return <source src={`${props.src}?apikey=${encodeURIComponent(_CFG.apiKey)}`} type={props.type || "application/x-mpegURL"} />;
};

const HLSSource = (props) => {
    const { src, isLive } = props;
    const [hls, setHls] = useState(null);

    useEffect(() => {
        const config = {
            debug: false, // _CFG.isLocal(),
            xhrSetup: (xhr, url) => {
                if (!isLive) {
                    xhr.setRequestHeader("apikey", _CFG.apiKey);
                    xhr.setRequestHeader("authorization", _CK.getAuth());
                }
            },
        };

        const h = new Hls(config);
        const { src, video, autoPlay } = props;
        if (Hls.isSupported()) {
            h.attachMedia(video);
            h.on(Hls.Events.MEDIA_ATTACHED, () => {
                h.loadSource(src);
                h.on(Hls.Events.MANIFEST_PARSED, () => {
                    if (autoPlay) {
                        setTimeout(() => {
                            video.play();
                        }, 1000);
                    }
                });
            });
        }

        setHls(h);

        return () => {
            if (hls) {
                hls.destroy();
            }
        };
    }, [src]);

    return <source src={src} type={props.type || "application/x-mpegURL"} />;
};
