import Cookies from "universal-cookie";
import url from "url";

const cm = new Cookies();

const getCookie = (key) => {
    return cm.get(key);
};

const setCookie = (key, value) => {
    cm.set(key, value, { path: "/", sameSite: "lax" });
};

const setCookies = (cookies, url) => {
    const keys = Object.keys(cookies);
    for (let i in keys) {
        const k = keys[i];
        const v = cookies[k];
        cm.set(k, v, { path: "/", sameSite: "lax" });
    }
};

const getCookies = (keys) => {
    const cookies = {};
    for (let i in keys) {
        const key = keys[i];
        cookies[key] = getCookie(key);
    }
    return cookies;
};

const getAuth = () => {
    return cm.get("authorization");
};

const setAuth = (auth) => {
    cm.set("authorization", auth, { path: "/", sameSite: "lax" });
};

const clearAuth = () => {
    cm.set("authorization", "", { path: "/", sameSite: "lax" });
};

const isLogin = () => {
    if (!getAuth()) {
        return false;
    }
    return true;
};

const setCFCookies = (data) => {
    const { cfSign, cfUrl } = data;
    const { hostname: host } = url.parse(cfUrl);
    const names = host.split(".");
    let hostname = `.${host}`;
    if (names.length > 2) {
        hostname = `.${names[names.length - 2]}.${names[names.length - 1]}`;
    }
    const keys = Object.keys(cfSign);
    for (let i in keys) {
        const k = keys[i];
        const v = cfSign[k];
        cm.set(k, v, { path: "/", sameSite: "lax", domain: hostname });
    }
};

export default {
    set: setCookie,
    get: getCookie,
    setCookies,
    getCookies,
    getAuth,
    setAuth,
    clearAuth,
    isLogin,
    setCFCookies,
};
