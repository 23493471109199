import React, { useContext, useEffect, useState, useRef, Fragment } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";

import { Loading } from "../../../components/UI/Loading";
import AmazonIVSWorkaround from "../../../components/AmazonIVSWorkaround";

export default (props) => {
    const { match } = props;
    const { location } = match.params;
    const { auth, cfg } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { idx: siteEventIdx } = event;

    const [isLogined, setIsLogined] = useState(false);
    const [isLoadSession, setIsLoadSession] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [session, setSession] = useState(null);
    const [quiz, setQuiz] = useState(null);

    const loadSessions = () => {
        _API.get({
            path: "/v1/site/event/sessions/room",
            data: {
                siteIdx,
                siteEventIdx,
                siteLocationDtlIdx: location,
            },
        }).then((res) => {
            setSessions(res.data);
            setIsLoadSession(true);
        });
    };

    const selectSession = () => {
        if (!isLoadSession || sessions?.list.length === 0) {
            setSession(null);
            return;
        }

        const now = moment();
        const day = now.format("YYYY-MM-DD");
        const fs = sessions?.list?.filter((s) => {
            const start = moment(`${day} ${s.startTime}`, "YYYY-MM-DD HH:mm:ss");
            const end = moment(`${day} ${s.endTime}`, "YYYY-MM-DD HH:mm:ss");
            return now.isBetween(start, end);
        });
        if (fs.length === 0) {
            setSession(null);
            return;
        }
        setSession(fs[0]);
    };

    const sendAlive = () => {
        if (!session || !isLogined) {
            return;
        }
        _API.post({
            path: "/v1/site/event/session/alive",
            data: {
                siteIdx,
                siteEventIdx,
                siteEventSessionIdx: session.idx,
            },
        }).then((res) => {});
        _U.postMessage("SOCKET-SEND", {
            action: "ROOM",
            data: {
                type: "MEMBER",
                room: location,
            },
        });
    };

    const onQuestion = () => {
        if (!session) {
            return;
        }

        swal({
            text: "질문을 입력해 주세요.",
            content: "input",
            buttons: ["취소", "보내기"],
        }).then((q) => {
            if (q) {
                _API.post({
                    path: "/v1/site/event/session/question",
                    data: {
                        siteIdx,
                        siteEventIdx,
                        siteEventSessionIdx: session.idx,
                        question: q,
                    },
                }).then((res) => {
                    _U.postMessage("SOCKET-SEND", {
                        action: "QUESTION",
                        data: {
                            room: location,
                        },
                    });
                    swal({ title: "알림", text: "질문을 등록했습니다." });
                });
            }
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "QUIZ") {
                    setQuiz(data.quiz);
                }
            });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);

        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(() => {
        if (event.useLogin === "Y" && !_CK.isLogin()) {
            swal({ title: "알림", text: "로그인 후 이용이 가능합니다." }).then(() => {
                window.location.href = "#/";
            });
            return;
        }
        loadSessions();
        setIsLogined(_CK.isLogin());
    }, [auth]);

    useEffect(() => {
        if (isLoadSession) {
            selectSession();
            const ss = setInterval(selectSession, 30000);
            return () => clearInterval(ss);
        }
    }, [isLoadSession]);

    useEffect(() => {
        if (session) {
            sendAlive();
            const sa = setInterval(sendAlive, 10000);
            return () => clearInterval(sa);
        }
    }, [session]);

    useEffect(() => {
        if (quiz) {
            setTimeout(() => {
                setQuiz(null);
            }, 15000);
        }
    }, [quiz]);

    const isIvs = (streamUrl) => {
        return streamUrl.includes("live-video.net");
    };

    if (event.useLogin === "Y" && !_CK.isLogin()) {
        return null;
    }

    if (!isLoadSession) {
        return <Loading title="세션을 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }

    if (!session) {
        return <Waiting>강의를 준비중입니다.</Waiting>;
    }

    return (
        <Fragment>
            <Title>
                {session.title}
                <Principal>
                    좌장 : <span dangerouslySetInnerHTML={{ __html: session?.principal || getPrincipal(sessions) }} />
                </Principal>
            </Title>
            <Console>
                <CPWrapper>
                    <CPlayer>{isIvs(getStreamUrl(event, location)) ? <AmazonIVSWorkaround url={getStreamUrl(event, location)} /> : `스트리밍 영역 Stream URL : ${getStreamUrl(event, location)}`}</CPlayer>
                </CPWrapper>
                <CIWrapper>
                    <CIQuestion onClick={onQuestion}>
                        <i className="fa fa-question"></i> 질문하기
                    </CIQuestion>
                    {session.files.length > 0 && (
                        <CIItem>
                            <CITitle>강의자료</CITitle>
                            <CIFiles>
                                {session.files.map((item, i) => (
                                    <li key={i}>
                                        {item.type === "C" && <span className="cv">CV</span>}
                                        <a href={`${cfg.cfUrl}${item.path}`} target="_blank">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </CIFiles>
                        </CIItem>
                    )}
                    {session.description && (
                        <CIItem>
                            <CITitle>강의내용</CITitle>
                            <CIDescription dangerouslySetInnerHTML={{ __html: session.description }} />
                        </CIItem>
                    )}
                </CIWrapper>
                <Chat {...{ siteIdx, siteEventIdx, siteEventSessionIdx: session.idx, siteLocationDtlIdx: location }} />
            </Console>
            {quiz && <Quiz {...{ siteIdx, siteEventIdx, siteEventSessionIdx: session.idx }} quiz={quiz} onSended={() => setQuiz(null)} />}
        </Fragment>
    );
};

const Chat = (props) => {
    const { iam } = useContext(AppContext);
    const { siteIdx, siteEventIdx, siteEventSessionIdx, siteLocationDtlIdx } = props;
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [isSendProc, setIsSendProc] = useState(false);
    const endRef = useRef(null);

    const scrollToBottom = () => {
        endRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            onSend();
        }
    };

    const onSend = () => {
        if (!message) {
            return;
        }
        if (isSendProc) {
            return;
        }
        setIsSendProc(true);
        _API.post({
            path: "/v1/site/event/session/chat",
            data: {
                siteIdx,
                siteEventIdx,
                siteEventSessionIdx,
                siteLocationDtlIdx,
                message,
            },
        }).then((res) => {
            _U.postMessage("SOCKET-SEND", {
                action: "CHAT",
                data: {
                    mode: "CHAT",
                    room: siteLocationDtlIdx,
                    name: iam?.name || "익명",
                    semi: iam?.siteEventMemberIdx,
                    memType: "MEMBER",
                    message,
                },
            });
            setMessage("");
            setIsSendProc(false);
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "CHAT") {
                    setMessages((o) => [...o.slice(-99), data]);
                }
            });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(scrollToBottom, [messages]);

    return (
        <CCWrapper>
            <CCTitle>채팅</CCTitle>
            <CCContent>
                {messages.map((item, i) => (
                    <CCMessage key={i} isAdmin={item.memType === "OPERATOR"} isMe={item.semi === iam?.siteEventMemberIdx}>
                        <span>{item.name}</span>
                        {item.message}
                    </CCMessage>
                ))}
                <div ref={endRef} />
            </CCContent>
            <CCSend>
                <input type="text" placeholder="내용을 입력해 주세요" value={message || ""} onChange={(e) => setMessage(e.target.value)} onKeyDown={onKeyDown} />
                <button type="button" onClick={onSend}>
                    <i className="fa fa-send"></i>
                </button>
            </CCSend>
        </CCWrapper>
    );
};

const Quiz = (props) => {
    const { siteIdx, siteEventIdx, siteEventSessionIdx, quiz, onSended } = props;

    const onQuizClick = (answer) => {
        _API.post({
            path: "/v1/site/event/session/quiz",
            data: {
                siteIdx,
                siteEventIdx,
                siteEventSessionIdx,
                siteEventSessionQuizIdx: quiz.idx,
                answer,
            },
        }).then((res) => {
            swal({ title: "알림", text: "참여해 주셔서 감사합니다." }).then(() => {
                onSended && onSended();
            });
        });
    };

    return (
        <QContainer>
            <QWrapper>
                <QTitle dangerouslySetInnerHTML={{ __html: quiz.question }} />
                {quiz.answer1 && <QAnswer onClick={onQuizClick.bind(this, 1)}>{quiz.answer1}</QAnswer>}
                {quiz.answer2 && <QAnswer onClick={onQuizClick.bind(this, 2)}>{quiz.answer2}</QAnswer>}
                {quiz.answer3 && <QAnswer onClick={onQuizClick.bind(this, 3)}>{quiz.answer3}</QAnswer>}
                {quiz.answer4 && <QAnswer onClick={onQuizClick.bind(this, 4)}>{quiz.answer4}</QAnswer>}
                {quiz.answer5 && <QAnswer onClick={onQuizClick.bind(this, 5)}>{quiz.answer5}</QAnswer>}
            </QWrapper>
        </QContainer>
    );
};

const getPrincipal = (list) => {
    const fl = list.list.filter((item) => !!item.principal);
    if (fl.length === 0) {
        return "";
    }
    return fl[0].principal;
};

const getStreamUrl = (event, location) => {
    const fl = event.locations.filter((loc) => loc.idx === parseInt(location));
    return fl.length === 0 ? "" : fl[0].streamPlayUrl;
};

/**** Styled-Components *******************/
const Waiting = styled.div`
    margin: 10rem 0rem;
    text-align: center;
`;
const Title = styled.h2`
    font-size: 1.25rem;
`;
const Principal = styled.small`
    float: right;
    clear: right;
    font-size: 0.9rem;

    @media only screen and (max-width: 768px) {
        float: none;
        clear: none;
        display: block;
        text-align: right;
    }
`;

const Console = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    row-gap: 1rem;
    column-gap: 1rem;
    @media only screen and (max-width: 768px) {
        display: block;
    }
`;
const CPWrapper = styled.div`
    margin: 0rem;
    grid-column: 1 / span 4;
    border: black dashed 1px;
    @media only screen and (max-width: 768px) {
        margin: 0;
    }
`;
const CPlayer = styled.div`
    min-height: 25rem;
    border: blue solid 1px;
    border-radius: 0.5rem;
    background: skyblue;
    text-align: center;

    @media only screen and (max-width: 768px) {
        min-height: 13.5rem;
    }
`;

const CIWrapper = styled.div`
    margin: 0rem;
    grid-column: 1 / span 2;
    border: black dashed 1px;
    @media only screen and (max-width: 768px) {
        margin: 1rem 0;
    }
`;

const CIQuestion = styled.button`
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: 100%;
    display: block;
    background-color: #fff;
    border: ${(props) => props.theme.color.primary} solid 1px;
    border-radius: 0.3rem;
`;

const CIItem = styled.div`
    margin-bottom: 1rem;
`;
const CITitle = styled.h4`
    margin-bottom: 0.5rem;
`;
const CIFiles = styled.ol`
    list-style: decimal;
    padding-left: 1.5rem;
    font-size: 0.9rem;
    li {
        margin-bottom: 0.5rem;
    }
    a {
        color: ${(props) => props.theme.color.primary};
        word-break: break-all;
    }
    a:hover {
        text-decoration: underline;
    }
    .cv {
        padding: 0.1rem 0.5rem 0.2rem;
        margin-right: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.5rem;
        font-weight: normal;
        background: red;
        color: #fff;
        border-radius: 0.3rem;
    }
`;
const CIDescription = styled.div`
    max-height: 15rem;
    overflow-x: hidden;
    overflow-y: auto;
`;

const CCWrapper = styled.div`
    border: #cccccc solid 1px;
    height: 25rem;
    grid-column: 3 / span 2;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
        height: 20rem;
    }
`;

const CCTitle = styled.h3``;
const CCContent = styled.div`
    padding: 1rem 0;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
`;
const CCMessage = styled.div`
    margin-bottom: 0.8rem;
    font-size: 0.8rem;
    ${(props) => props.isMe && `text-align:right;`}
    span {
        margin-right: 0.5rem;
        padding: 0rem 0.5rem;
        ${(props) => props.isMe && `display:none;`}
        border:#cccccc solid 1px;
        border-radius: 0.3rem;
    }
`;
const CCSend = styled.div``;

const QContainer = styled.div`
    padding: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background: rgba(0, 0, 0, 0.7);
    border: none;
    z-index: 2000;
    align-items: center;
    justify-content: center;
`;

const QWrapper = styled.div`
    padding: 1rem;
    width: 100%;
    max-width: 30rem;
    border: #cccccc solid 1px;
    border-radius: 0.5rem;
    background: #ffffff;
`;

const QTitle = styled.div`
    margin: 0 0 1rem;
`;
const QAnswer = styled.button`
    margin: 0 0 0.5rem;
    padding: 0.5rem 0;
    width: 100%;
    display: block;
    border: #cccccc solid 1px;
    border-radius: 0.3rem;
`;
