const utils = {};

utils.isEmail = (email) => {
    if (!email) {
        return false;
    }
    const reg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
    if (reg.test(email.toLowerCase())) {
        return true;
    }
    return false;
};

utils.encode = (o) => {
    return btoa(encodeURIComponent(JSON.stringify(o)));
};

utils.decode = (str) => {
    return JSON.parse(decodeURIComponent(atob(str)));
};

utils.setSession = (code, value) => {
    window.sessionStorage.setItem(code, utils.encode(value));
};

utils.getSession = (code) => {
    if (window.sessionStorage.getItem(code)) {
        return utils.decode(window.sessionStorage.getItem(code));
    }
    return false;
};

utils.removeSession = (code) => {
    window.sessionStorage.removeItem(code);
};

utils.postMessage = (code, data) => {
    window.postMessage(JSON.stringify({ code: code, data: data }), window.location.origin);
};

utils.parseMessage = (e) => {
    return new Promise((resolve, reject) => {
        if (!e.data) {
            reject && reject();
            return;
        }
        let data = null;
        try {
            data = typeof e.data === "string" && e.data.startsWith("{") ? JSON.parse(e.data) : e.data;
        } catch (e) {
            reject && reject();
            return;
        }
        try {
            if (data && data.code) {
                resolve && resolve(data);
            }
        } catch (e) {
            reject && reject(e);
        }
    });
};

export default utils;
