import { createGlobalStyle, ThemeProvider as Provider } from "styled-components";
import reset from "styled-reset";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "filepond/dist/filepond.min.css";
import "react-summernote/dist/react-summernote.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "video-react/dist/video-react.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "../resources/styles.css";

const theme = {
    color: {
        primary: "#333333",
        secondary: "#34495e",
        background: "#ecf0f1",
    },
    basic: {
        fontSize: 16,
        width: 918,
        color: {
            primary: "#1889ea",
        },
    },
    big: {
        fontSize: 16,
        width: 1300,
        color: {
            primary: "#1889ea",
        },
    },
    header: {
        height: 50,
    },
    nav: {
        height: 50,
    },
    menu: {
        height: 50,
    },
    responsive: {
        minWidth: 918,
    },
};

const globalStyle = createGlobalStyle`
    ${reset}
    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none !important;
    }
    body {
        font-size:${theme.basic.fontSize}px;
        background-color:${theme.color.background};
        color:${theme.color.primary};
        font-family:NotoSansCJKkr;
    }
    input, textarea {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
    a {
        font-size:0.875rem;
    }
    a:hover {
        text-decoration:none;
    }
`;

export const Theme = theme;
export const ThemeProvider = Provider;
export default globalStyle;
